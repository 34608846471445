.ant-input-affix-wrapper .ant-input-suffix {
  right: 10px;
  z-index: 0;
}

.hidden {
  display: none !important;
}

.ant-select-dropdown,
.ant-select-tree-dropdown {
  max-height: 45vh !important;
}

.ant-select-tree-checkbox-disabled {
  display: none !important;
}

.ant-form-item-label > label {
  color: rgba(0, 0, 0, 0.55);
  font-size: 1rem;
  text-transform: capitalize;
}

.ant-select-tree,
.ant-tooltip,
.recharts-wrapper {
  text-transform: capitalize;
}

th, tr {
  text-align: center !important;
}